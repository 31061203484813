import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import * as React from 'react'
import Fade from 'react-reveal/Fade';
import Animate from '../Animate';
import Section, {Title} from '../Section';
import {Caption} from '../Typography';

const Clients = () => {
  // const isMotionSafe = useMotionSafe();
  const {clients} = useStaticQuery(graphql`
    query {
      clients: allClientsJson {
        nodes {
          id
          name
          show
          image {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
      }
    }
  `)
  return (
    <Section name="clients" className="pb-24">
      <Title title="Clients" superTitle="Who we Work With"></Title>
      <div className="flex flex-wrap justify-center">
        {clients.nodes.filter(it => it.show !== false).map((c,i) => {
          const Img = <GatsbyImage alt={c.name} image={getImage(c.image)} className="h-32" objectFit="contain"/>
          return <div key={c.id} className="w-32 sm:w-48 p-4">
            <div className="w-full flex">
              <Animate Type={Fade} bottom delay={i*50}>
                {Img}
              </Animate>
            </div>
            <Caption className="text-center pt-1">{c.name}</Caption>
          </div>
        })}
      </div>
    </Section>
  )
}

export default Clients;
