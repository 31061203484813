import * as React from 'react'
import Footer from '../comps/Footer';
import Acknowledgement from '../comps/home/Acknowledgement';
import Clients from '../comps/home/Clients';
import Contact from '../comps/home/Contact';
import Hero from '../comps/home/Hero'
import Team from '../comps/home/Team';
import Mission from '../comps/home/Mission';
import Portfolio from '../comps/home/Portfolio';
import Services from '../comps/home/Services';

const IndexPage = () => {

  return (
    <main className="font-sans">
      <Hero/>
      <Mission/>
      <Acknowledgement/>
      <Services/>
      <Team/>
      <Portfolio/>
      <Clients/>
      <Contact/>
      <Footer/>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Sarah Fotheringham Consulting</title>
