import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Zoom from 'react-reveal/Zoom';
import Animate from '../Animate';
import Container from '../Container';
import {P} from '../Typography';

const Acknowledgement = () => {
  return (
    <div className="grid">
      <StaticImage
        src="../../assets/images/land.jpg"
        alt="The bow valley"
        layout="fullWidth"
        objectPosition="top"
        className="row-start-1 col-start-1 h-screen-1/2"/>
      <div className="grid relative row-start-1 col-start-1 place-items-center text-white">
        <div className="w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-80 sm:bg-opacity-0 px-3">
          <Animate Type={Zoom}>
            <Container className="flex flex-col justify-center items-center max-w-5xl sm:bg-black sm:bg-opacity-75 sm:p-6 md:p-16">
              <P>
                In the spirit of reconciliation, we honour and acknowledge the traditional and ancestorial
                territories of the peoples of Treaty 7 comprising the Siksika, Piikani, and Kainai First
                Nations, the Tsuut’ina First Nation, the Îyârhe Nakoda (Stoney Nakoda) First Nations
                including the Chiniki, Bearspaw, and Wesley First Nations, and Region III of the Métis
                Nation of Alberta. We honor this treaty land where we live, work and play and commit to
                ongoing learning and growth towards reconciliation.
              </P>
            </Container>
          </Animate>
        </div>
      </div>
    </div>
  )
}

export default Acknowledgement;
