import React from 'react';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Animate from '../Animate';
import Section, {Title} from '../Section';
import {H5, Body1} from '../Typography';

const Services = () => {
  const {primary, secondary} = useStaticQuery(graphql`
    query {
      primary: allServicesJson(filter:{theme: {eq: "primary"}}) {
        nodes {
          id
          title
          text
          image {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
      }
      
      secondary: allServicesJson(filter:{theme: {eq: "secondary"}}) {
        nodes {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 512)
            }
          }
        }
      }
    }
  `)

  return (
    <Section name="services">
      <Title title="Services" superTitle="What We Do" className="mb-16">
        {/*<P>*/}
        {/*  I love working with organizations, systems and sectors to help them achieve their most effective and innovative services delivery strategies. What drives me is the ultimate goal of improved social outcomes for people and communities.*/}
        {/*</P>*/}
      </Title>
      <div className="flex flex-wrap justify-center">
        {primary.nodes.map((s,i) => {
          return <div key={s.id} className="w-full lg:w-1/2 lg:pr-8 pb-12">
            <div className="w-full flex">
              <div className="flex-grow flex-shrink-0 pr-4">
                <Animate Type={Zoom} delay={i*150}>
                  <GatsbyImage alt="" image={getImage(s.image)} className="w-24 md:w-32 lg:w-40 h-24 md:h-32 lg:h-40 object-cover object-center rounded-full drop-shadow-2xl"/>
                </Animate>
              </div>
              <div>
                <Animate Type={Fade} delay={i*150}>
                  <H5 className="uppercase text-secondary">{s.title}</H5>
                </Animate>
                {/*<Animate Type={Fade} delay={i*150 + 50}>*/}
                {/*  <Caption className="uppercase">{s.sub_title}</Caption>*/}
                {/*</Animate>*/}
                <Animate Type={Fade} delay={i*150 + 100}>
                  <Body1 className="mt-2 md:mt-4">
                    {s.text}
                  </Body1>
                </Animate>
              </div>
            </div>
          </div>
        })}
      </div>
      <Animate Type={Fade} delay={150}>
        <H5 className="font-thin uppercase text-center text-primary">Other Services</H5>
      </Animate>
      <div className="flex flex-wrap justify-evenly mt-8">
        {secondary.nodes.map((s,i) =>
          <Animate Type={Zoom} delay={150*3 + i*150} key={s.id}>
            <div className="grid w-40 md:w-48 h-40 md:h-48 mb-4">
              <GatsbyImage
                image={getImage(s.image)}
                alt={s.title}
                layout="fullWidth"
                objectPosition="top"
                className="row-start-1 col-start-1 h-full w-full rounded-full drop-shadow-2xl"/>
              <div className="grid relative row-start-1 col-start-1 place-items-center text-white">
                <div className="w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-50 rounded-full p-2">
                  <H5 className="uppercase text-center font-black">
                    {s.title}
                  </H5>
                </div>
              </div>
            </div>
          </Animate>
        )}
      </div>
    </Section>
  )
}

export default Services;


