import * as React from 'react';
import Zoom from 'react-reveal/Zoom';
import {StaticImage} from 'gatsby-plugin-image';
import Logo from '../../assets/svgs/logo-w.svg';
import DownArrow from '../../assets/svgs/down-arrow-x2.svg';
import Animate from '../Animate';
import Container from '../Container';


const Hero = () => {
  const links = [{
    name: 'Services', href: '#services'
  }, {
    name: 'Team', href: '#team'
  }, {
    name: 'Portfolio', href: '#portfolio'
  }, {
    name: 'Clients', href: '#clients'
  }, {
    name: 'Contact', href: '#contact'
  }];

  return (
    <div className="grid h-screen">
      <StaticImage
        src="../../assets/images/hero.jpg"
        alt="Sunlight through trees"
        layout="fullWidth"
        objectPosition="top"
        className="row-start-1 col-start-1"/>
      <div className="grid relative row-start-1 col-start-1 place-items-center text-white">
        <div className="w-full h-full flex flex-col justify-center items-center bg-gradient-to-b from-transparent via-black/60 to-black/80 ">
          <Container className="flex flex-col justify-center items-center">
            <Logo className="w-72 md:w-[32rem] p-2 md:p-16"/>
            <nav className="w-full flex flex-wrap justify-evenly mt-8">
              {links.map((l,i) => <Animate Type={Zoom} delay={i*200} key={l.name}>
                <div className="w-1/2 sm:w-1/5 text-center py-4">
                  <a href={l.href} className="uppercase tracking-wider text-lg sm:text-xl transition-transform px-4 text-center">{l.name}</a>
                </div>
                </Animate>
              )}
            </nav>
            <DownArrow className="fill-current w-12 sm:w-16 h-initial mt-16 sm:mt-24 lg:mt-32 motion-safe:animate-bounce"
              style={{animationDuration: '2000ms'}}/>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Hero;
