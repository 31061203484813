import React from 'react';
import Fade from 'react-reveal/Fade';
import Animate from '../Animate';
import Section from '../Section';
import Logo from '../../assets/svgs/logo-c.svg'
import {Body1} from '../Typography';

const Mission = () => {
  return (
    <Section name="mission" className="">
      <div className="flex flex-wrap items-center">
        <div className="w-full md:w-3/4">
          <Animate Type={Fade}>
            <Body1>
              Sarah Fotheringham Consulting Ltd. is a consulting firm offering research and evaluation services to the public and
              non-profit sector. We bring technical expertise in program design, evaluation, community-based research, and social
              service innovation.
            </Body1>
          </Animate>
          <Animate Type={Fade} delay={300}>
            <Body1 className="mt-4">
              Our aim is to provide the highest quality of work to enable effective evidence-based service delivery practices and
              ultimately achieve improved social outcomes for people and communities.
            </Body1>
          </Animate>
        </div>
        <div className="w-full md:w-1/4">
          <Logo className="w-32 md:w-full ml-auto px-4"/>
        </div>
      </div>
    </Section>
  )
}

export default Mission;
