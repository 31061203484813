import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import React from 'react';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Animate from '../Animate';
import Section, {Title} from '../Section';
import {Body2, Caption, H4, H6, P, SubCaption} from '../Typography';
import UOFCLogo from '../../assets/images/uofc-logo.png'
import ACSWLogo from '../../assets/images/acsw-logo.jpg'
import CESLogo from '../../assets/images/ces-logo.png'


const Team = () => {
  const {headshots} = useStaticQuery(graphql`
    query {
      headshots: allFile (filter: {relativeDirectory: {eq: "team"}}) {
        nodes {
          name
          childImageSharp {
           gatsbyImageData(width: 512)
          }
        }
      }
    }
  `)
  return (
    <Section name="team">
      <div className="flex flex-col-reverse flex-wrap sm:flex-row items-center">
        <div className="w-full sm:w-1/2">
          <div className="w-full flex flex-col justify-center items-center">
            <Animate Type={Zoom}>
              <GatsbyImage alt="Sarah Fotheringham" image={getImage(headshots.nodes.find(it => it.name === 'sarah'))}
                           className="rounded-full h-64 w-64 drop-shadow-md"/>
            </Animate>
            <div className="pt-4 text-center">
              <div className="flex items-end">
                <H4 className="text-primary">Dr. Sarah Fotheringham</H4>
                <Caption className="mb-[2px] ml-2 text-primary font-medium">PHD, RSW</Caption>
              </div>

              <Caption className="uppercase">Principal Consultant</Caption>
              <Caption className="italic">Pronouns: she/her</Caption>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <Title title="About Our Team" superTitle="Who we are" className="mb-8">
            <Animate Type={Fade} delay={150}>
              <P className="mb-2">
                Sarah brings over 20 years of social service experience to her consulting practice. She
                has a doctorate degree in Social Work and is a Registered Social Worker. Sarah has
                led and collaborated on research and evaluation activities since 2005. She has written
                numerous reports and published several academic articles. Areas of expertise include
                gender-based violence prevention and engaging men and boys. She has also worked in
                areas such as homelessness, addiction, pregnant/parenting teens and youth services.
                Sarah is passionate about supporting agencies in developing innovative and effective
                evidence-based services.
              </P>
            </Animate>

            {/*<div className="flex justify-between mt-8 relative hidden">*/}
            {/*  <hr className="absolute top-1/3 left-1/2 -translate-x-1/2 w-4/5 mx-auto border-gray-300"/>*/}
            {/*  <div className="w-1/4 text-center">*/}
            {/*    <Animate Type={Zoom} delay={150}>*/}
            {/*      <StaticImage src="../../assets/images/uvic-logo-square.png" alt=""*/}
            {/*                   className="rounded-full border-2 border-gray drop-shadow-2xl w-24"/>*/}
            {/*    </Animate>*/}
            {/*    <Caption class="px-4">*/}
            {/*      University of Victoria*/}
            {/*    </Caption>*/}
            {/*    <Caption className="font-bold">*/}
            {/*      1994 - 1998*/}
            {/*    </Caption>*/}
            {/*  </div>*/}
            {/*  <div className="w-1/4 text-center">*/}
            {/*    <Animate Type={Zoom} delay={300}>*/}
            {/*      <StaticImage src="../../assets/images/uofc-logo-square.png" alt=""*/}
            {/*                   className="rounded-full border-2 border-gray drop-shadow-2xl w-24"/>*/}
            {/*    </Animate>*/}
            {/*    <Caption class="px-4">*/}
            {/*      University of Calgary*/}
            {/*    </Caption>*/}
            {/*    <Caption className="font-bold">*/}
            {/*      2005 - 2008*/}
            {/*    </Caption>*/}
            {/*  </div>*/}
            {/*  <div className="w-1/4 text-center">*/}
            {/*    <Animate Type={Zoom} delay={450}>*/}
            {/*      <StaticImage src="../../assets/images/emes-logo.png" alt=""*/}
            {/*                   className="rounded-full border-2 border-gray drop-shadow-2xl w-24 ml-auto"/>*/}
            {/*    </Animate>*/}
            {/*    <Caption class="px-4">*/}
            {/*      EMES International Research Network*/}
            {/*    </Caption>*/}
            {/*    <Caption className="font-bold">*/}
            {/*      2014*/}
            {/*    </Caption>*/}
            {/*  </div>*/}
            {/*  <div className="w-1/4 text-center">*/}
            {/*    <Animate Type={Zoom} delay={600}>*/}
            {/*      <StaticImage src="../../assets/images/uofc-logo-square.png" alt=""*/}
            {/*                   className="rounded-full border-2 border-gray drop-shadow-2xl w-24"/>*/}
            {/*    </Animate>*/}
            {/*    <Caption class="px-4">*/}
            {/*      University of Calgary*/}
            {/*    </Caption>*/}
            {/*    <Caption className="font-bold">*/}
            {/*      2011 - 2016*/}
            {/*    </Caption>*/}
            {/*  </div>*/}

            {/*</div>*/}
          </Title>
        </div>
      </div>
      <div className="w-full flex justify-center flex-wrap my-4">
        <Animate Type={Fade} delay={150}>
          <div className="w-64 sm:w-80 flex items-center mt-4">
            <img src={UOFCLogo} alt="University of Calgary Logo" className="w-8 h-auto"/>
            <div className="grow-1 shrink-0 pl-1 text-left">
              <H6>University of Calgary</H6>
              <SubCaption className="uppercase">Adjunct Assistant Professor</SubCaption>
            </div>
          </div>
        </Animate>
        <Animate Type={Fade} delay={300}>
          <div className="w-64 sm:w-80 flex items-center mt-4">
            <img src={ACSWLogo} alt="Alberta College of Social Workers Logo"  className="w-8 h-auto"/>
            <div className="grow-1 shrink-0 pl-1 text-left">
              <H6>Alberta College of Social Workers</H6>
              <SubCaption className="uppercase">Registered Social Worker</SubCaption>
            </div>
          </div>
        </Animate>
        <Animate Type={Fade} delay={450}>
          <div className="w-64 sm:w-80 flex items-center mt-4">
            <img src={CESLogo} alt="Canadian Evaluation Society Logo" className="w-8 h-auto"/>
            <div className="grow-1 shrink-0 pl-1 text-left">
              <H6>Canadian Evaluation Society</H6>
              <SubCaption className="uppercase">Member</SubCaption>
            </div>
          </div>
        </Animate>
      </div>
      <div className="w-full mt-16 lg:mt-32">
        <div className="w-full flex flex-col sm:flex-row justify-center items-start sm:p-4">
          <Animate Type={Zoom} delay={150}>
            <GatsbyImage alt="Staci Hastings" image={getImage(headshots.nodes.find(it => it.name === 'staci'))}
                         className="rounded-full w-32 sm:w-48 h-32 sm:h-48 drop-shadow-md"/>
          </Animate>
          <Animate Type={Slide} delay={150} right={true}>
            <div className="sm:pl-4">
              <div className="flex items-end">
                <H4 className="text-primary">Staci Hastings</H4>
                <Caption className="mb-[2px] ml-2 text-primary font-medium">MPH</Caption>
              </div>
              <Caption className="uppercase">Research Associate</Caption>
              <Caption className="italic">Pronouns: she/her</Caption>
              <Body2 className="mt-2">
                Staci Hastings joined our team as a Research Associate in the spring of 2020. With seven years of applied health research and evaluation experience, she brings a diverse range of skills and a valuable perspective to our work.
              </Body2>
              <Body2 className="mt-1">
                Staci recently graduated from the Master of Public Health (MPH) in Health Promotion program at the University of Alberta. She is passionate about improving the health and well-being of individuals and communities through evidence-based quality improvement initiatives and is eager to continue supporting evaluation and innovation within the social service sector.
              </Body2>
            </div>
          </Animate>
        </div>
      </div>
      <div className="w-full mt-8">
        <div className="w-full flex flex-col sm:flex-row justify-center items-end sm:items-start sm:p-4">
          <Animate Type={Zoom} delay={150}>
            <GatsbyImage alt="Naomi Phung" image={getImage(headshots.nodes.find(it => it.name === 'naomi'))}
                         className="rounded-full w-32 sm:w-48 h-32 sm:h-48 drop-shadow-md"/>
          </Animate>
          <Animate Type={Slide} delay={150} right={true}>
            <div className="sm:pl-4">
              <div className="text-right sm:text-left">
                <div className="flex justify-end sm:justify-start items-end">
                  <H4 className="text-primary">Naomi Phung</H4>
                  <Caption className="mb-[2px] ml-2 text-primary font-medium">MA</Caption>
                </div>
                <Caption className="uppercase">Research Assistant</Caption>
                <Caption className="italic">Pronouns: she/her</Caption>
              </div>
              <Body2 className="mt-2">
                Naomi recently completed her Master of Arts in Social and Personality Psychology and is now an incoming PhD candidate in Historical, Theoretical, and Critical Psychology at York University. She has previous research experience on preventing domestic violence, Critical Race Theory, and racial discrimination. Naomi hopes to further develop her expertise on domestic violence and its related forms through her involvement in this team. She is also passionate about interdisciplinary research and working closely with community members to address their needs.
              </Body2>
            </div>
          </Animate>
        </div>
      </div>
      <div className="w-full mt-8">
        <div className="w-full flex flex-col sm:flex-row justify-center sm:p-4">
          <Animate Type={Zoom} delay={150}>
            <GatsbyImage alt="Nicole Bortsi" image={getImage(headshots.nodes.find(it => it.name === 'richie'))}
                         className="rounded-full w-32 sm:w-48 h-32 sm:h-48 drop-shadow-md"/>
          </Animate>
          <Animate Type={Slide} delay={150} right={true}>
            <div className="sm:pl-4">
              <div className="">
                <div className="flex items-end">
                  <H4 className="text-primary">Richard Martensz</H4>
                  <Caption className="mb-[2px] ml-2 text-primary font-medium">BSW</Caption>
                </div>
                <Caption className="uppercase">Research Assistant</Caption>
                <Caption className="italic">Pronouns: he/him</Caption>
              </div>
              <Body2 className="mt-2">
                Richie joined us in May 2024 after completing his final practicum with the team and graduating with a Bachelor of Social Work from the University of Calgary. He has a strong interest in engaging men and boys in the prevention of gender-based violence and in supporting men working in the gender-based violence field. He is passionate about acquiring and developing the skills necessary to support vulnerable populations and is committed to utilizing research and evidence-based practices to achieve this.
              </Body2>
            </div>
          </Animate>
        </div>
      </div>
    </Section>
  )
}

export default Team;
