import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import Button from '../Button';
import Project from '../Project';
import Section, {Title} from '../Section';

const Portfolio = () => {
  const {projects, clients} = useStaticQuery(graphql`
    query {
      projects: allProjectsJson(filter: {feature: {eq: true}}) {
        nodes {
          id
          title
          caption
          pending
          category
          partners
          image {
            childImageSharp {
              gatsbyImageData(width: 512, quality: 100)
            }
          }
        }
      }
      clients: allClientsJson {
        nodes {
          id
          key
          name
          image {
            childImageSharp {
              gatsbyImageData(width: 128)
            }
          }
        }
      }
    }
  `)
  const clientsByKey = clients.nodes.reduce((obj, n) => {
    obj[n.key] = n;
    return obj;
  },{});

  return (
    <Section name="portfolio">
      <Title title="Portfolio" superTitle="What We've Done" className="mb-16">
        {/*<P>*/}
        {/*  I bring 18 years of social work experience to all of my projects. I’ve had the opportunity to work frontline, in crisis work, which helps ground the way I do program design, evaluation and research. I’ve also worked in community development, research and policy thereby bringing extensive knowledge about the complex interplay between systems, community, organizations and program development.*/}
        {/*</P>*/}
      </Title>
      <div className="flex flex-wrap">
        {projects.nodes.map((p, i) => <Project key={p.id} project={p} index={i} clients={clientsByKey} />)}
      </div>
      <div className="text-center mt-8">
        <Button href='/portfolio'>See More</Button>
      </div>
    </Section>
  )
}

export default Portfolio;
